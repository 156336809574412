const features = {
  'discount_section:edit_page:premium_brand_labels': true,

  editorial_promo_blocks: true,

  campaign_markdown_content: true,
  campaign_evergreen: true,

  facets: false,
  promoGame: true,
  locationBasedOffers: true,
  translations: false,
  instantTranslator: true,
  new_connect_customisation: false
}

export default features
