import { Theme } from '@mui/material/styles'

import MuiButton from './mui_button'
import MuiTableCell from './mui_table_cell'
import MuiTablePagination from './mui_table_pagination'

const Components: Theme['components'] = {
  MuiButton,
  MuiTableCell,
  MuiTablePagination,
  MuiCard: {
    styleOverrides: {
      root: {
        overflow: 'visible'
      }
    }
  }
}

export default Components
